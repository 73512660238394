<template>
    <div>
        <van-cell-group :title="$t('陆运海运包裹统计', { totalByLand, totalBySea })">
            <van-cell size="large" :title="$t('付陆运包裹费用')" is-link custom-class="choise-item" :to="{
                name: 'order',
                query: {
                    refresh: 1,
                    expressWay: 1,
                    v3: true
                }
            }"></van-cell>

            <van-cell size="large" :title="$t('付海运包裹费用')" is-link
                custom-class="choise-item" :to="{
                    name: 'order',
                    query: {
                        refresh: 1,
                        expressWay: 2,
                        v3: true
                    }
                }"></van-cell>
        </van-cell-group>
    </div>
</template>
  
<script>
import { Cell, CellGroup } from "vant";
export default {
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
    },
    data() {
        return {
            totalByLand: 0,
            totalBySea: 0
        };
    },
    mounted() {
    const { totalByLand,totalBySea } = this.$route.query;
    this.totalBySea = totalBySea;
    this.totalByLand = totalByLand;
  },
};
</script>
<style lang="less" scoped>
::v-deep .van-cell-group__title {
    font-size: 1rem;
    color: #333;
    padding: 16px;
}
</style>