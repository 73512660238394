var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-cell-group',{attrs:{"title":_vm.$t('陆运海运包裹统计', { totalByLand: _vm.totalByLand, totalBySea: _vm.totalBySea })}},[_c('van-cell',{attrs:{"size":"large","title":_vm.$t('付陆运包裹费用'),"is-link":"","custom-class":"choise-item","to":{
            name: 'order',
            query: {
                refresh: 1,
                expressWay: 1,
                v3: true
            }
        }}}),_c('van-cell',{attrs:{"size":"large","title":_vm.$t('付海运包裹费用'),"is-link":"","custom-class":"choise-item","to":{
                name: 'order',
                query: {
                    refresh: 1,
                    expressWay: 2,
                    v3: true
                }
            }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }